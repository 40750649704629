import { startCase } from 'lodash';
// Constants
import { URL_SLUGS_RESIDENCES, VALID_PREFIXS_SLUGS, URL_SLUGS_LOCALE_ACTIVITIES } from '../constants';

/**
 * @param {string} slug
 * @param {string} locale
 * @param {string} prefix
 * @returns {string}
 */
export function completePageSlug(slug, locale, prefix) {
  return `${locale ? `${locale}-` : ''}${prefix ? `${prefix}-` : ''}${slug}`;
}

/**
 * @param {string} fullSlug
 * @param {string} locale
 * @returns {string}
 */
export function replacePageSlug(fullSlug, locale) {
  if (!fullSlug) return '';
  const withoutSlugPrefix = fullSlug.replace('slug:', '');

  const prefixRegex = new RegExp(`(${VALID_PREFIXS_SLUGS.join('|')})`);
  const match = withoutSlugPrefix.match(prefixRegex);
  const prefixToRemove = match ? match[1] : '';

  const withoutLocale = withoutSlugPrefix.replace(`${locale ? `${locale}-` : ''}`, '');

  return withoutLocale.replace(new RegExp(`^${prefixToRemove}-`), '');
}

export const pageSlugToTitle = (slug) => startCase(slug);

/**
 * @description Get the slug of a residence
 * @param {string} locale
 * @param {string} slug
 * @param {string} activity
 */
export const getSlugResidence = (locale, slug, activity) => {
  if (slug && !activity) {
    const cleanedSlug = replacePageSlug(slug, locale);
    return `/${URL_SLUGS_RESIDENCES[locale]}/${cleanedSlug}`;
  }
  if (slug && activity) {
    const cleanedSlug = replacePageSlug(slug, locale);
    return `/${URL_SLUGS_LOCALE_ACTIVITIES[locale][activity]}/${cleanedSlug}`;
  }
  return `/${URL_SLUGS_RESIDENCES[locale]}`;
};
